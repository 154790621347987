@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {


  * {
    @apply border-border;
    font-family: 'Roboto', sans-serif;

  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;

  }
}

#swift-app {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  font-family: 'Roboto', sans-serif;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  -moz-appearance: textfield;
}


body::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
body {
  scrollbar-width: none; /* Firefox 64 and later */
}

/* For Microsoft Edge and Internet Explorer */
body {
  -ms-overflow-style: none; /* Hide scrollbar in Edge and IE */
}


.ant-switch-checked{
  background:#1E232C !important;
}

#ProductInfoPrintable {
  a.comment-indicator:hover + div.comment {
    background: #ffd;
    position: absolute;
    display: block;
    border: 1px solid black;
    padding: 0.5em
  }

  a.comment-indicator {
    background: red;
    display: inline-block;
    border: 1px solid black;
    width: 0.5em;
    height: 0.5em
  }

  div.comment {
    display: none
  }

  table {
    border-collapse: collapse;
    page-break-after: always
  }

  .gridlines td {
    border: 1px dotted black
  }

  .gridlines th {
    border: 1px dotted black
  }

  .b {
    text-align: center
  }

  .e {
    text-align: center
  }

  .f {
    text-align: right
  }

  .inlineStr {
    text-align: left
  }

  .n {
    text-align: right
  }

  .s {
    text-align: left
  }

  td.style0 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  th.style0 {
    vertical-align: bottom;
    border-bottom: none #000000;
    border-top: none #000000;
    border-left: none #000000;
    border-right: none #000000;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  td.style1 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  th.style1 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  td.style2 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 8pt;
    background-color: white
  }

  th.style2 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 8pt;
    background-color: white
  }

  td.style3 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 12pt;
    background-color: white
  }

  th.style3 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 12pt;
    background-color: white
  }

  td.style4 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  th.style4 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    font-weight: bold;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  td.style5 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  th.style5 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'Calibri';
    font-size: 11pt;
    background-color: white
  }

  td.style6 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'New ';
    font-size: 26pt;
    background-color: white
  }

  th.style6 {
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #000000 !important;
    border-top: 1px solid #000000 !important;
    border-left: 1px solid #000000 !important;
    border-right: 1px solid #000000 !important;
    color: #000000;
    font-family: 'New ';
    font-size: 26pt;
    background-color: white
  }

  table.sheet0 col.col0 {
    width: 42pt
  }

  table.sheet0 col.col1 {
    width: 42pt
  }

  table.sheet0 col.col2 {
    width: 59.64444376pt
  }

  table.sheet0 col.col3 {
    width: 79.97777686pt
  }

  table.sheet0 col.col4 {
    width: 69.81111031pt
  }

  table.sheet0 tr {
    height: 15pt
  }

  table.sheet0 tr.row0 {
    height: 53pt
  }

  table.sheet0 tr.row1 {
    height: 48pt
  }

  table.sheet0 tr.row3 {
    height: 21pt
  }

  table.sheet0 tr.row4 {
    height: 38pt
  }

  table.sheet0 tr.row5 {
    height: 45pt
  }

  table.sheet0 tr.row6 {
    height: 26pt
  }

  table.sheet0 tr.row7 {
    height: 26pt
  }
}